
import router from "@/router"
import {ref} from "vue"

export default {
	name: "FormHeader",
	setup(props, context) {
		let showStart = ref(context.attrs.showStart)
		let showReview = ref(context.attrs.showReview)

		function navigateHome() {
			router.push("/home")
		}

		function navigateStart() {
			router.push("/start")
		}

		function navigateReview() {
			router.push("/review")
		}

		return {showStart, showReview, navigateHome, navigateStart, navigateReview}
	}
}
